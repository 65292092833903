import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

import { CompanyMetricValue } from './types';

export function getCompanyMetricsEvolutionColumnDefinition(
  t: TFunction,
): ColDef<CompanyMetricValue>[] {
  return [
    {
      field: 'metricName',
      headerName: t(
        'pages.fundManager.portfolio.company.metrics.table.metricName',
      ),
      rowGroup: true,
      enableRowGroup: true,
      filter: true,
    },
    {
      field: 'date',
      type: 'date',
      pivot: true,
      filter: true,
    },
    {
      field: 'value',
      aggFunc: 'sum',
      type: AgGridColumnTypesEnum.VALUE_WITH_10X8_DIVIDER,
      hide: true,
    },
  ];
}
