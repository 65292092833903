import { Navigate, useLocation } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { DeclineReason } from 'business/shared/lp-creation-module/providers/draft-edition-steps-provider';
import { getCreationStepDetails } from 'business/shared/lp-creation-module/services/get-creation-steps-details';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { ClientTypeEnum } from 'generated/graphql';

interface LpCreationRedirectProps {
  lpId: string;
  onboardingSubscriptionId: string;
  clientType: ClientTypeEnum;
  declineReasons: DeclineReason[];
}
export const LpCreationRedirect = ({
  lpId,
  clientType,
  onboardingSubscriptionId,
  declineReasons,
}: LpCreationRedirectProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const displayLpInvestorProfileStep = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
  );

  const isLpPlatform = useIsLpPlatform();

  const { steps } = getCreationStepDetails({
    lpId,
    t,
    clientType,
    currentUrl: pathname,
    declineReasons,
    displayLpInvestorProfileStep,
    displayLpContactsStep: !isLpPlatform,
  });

  const firstDeclinedStep = steps.find((step) => !!step.declineComment);

  const redirectionUrl = `${LpPlatformRoutes.LpOnboardingSubscriptionCreateIdLp.replace(
    ':onboardingSubscriptionId',
    onboardingSubscriptionId,
  )}${firstDeclinedStep ? firstDeclinedStep.url : `/${lpId}`}`;

  return <Navigate to={redirectionUrl} />;
};
