import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { FinancialFlowRow } from 'business/shared/financial-flows/services/types';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatAsDate } from 'technical/date';

export const getFinancialFlowsColumnDefinition = (
  t: TFunction,
): ColDef<FinancialFlowRow>[] => {
  return [
    {
      field: 'date',
      headerName: t('pages.fundManager.financialFlows.table.date'),
      enableRowGroup: false,
      filter: true,
      cellRenderer: ({ value }: ICellRendererParams) => {
        return formatAsDate(value);
      },
    },
    {
      field: 'fundName',
      headerName: t('pages.fundManager.financialFlows.table.fundName'),
      enableRowGroup: false,
      filter: true,
    },
    {
      field: 'share',
      headerName: t('pages.fundManager.financialFlows.table.share'),
      enableRowGroup: false,
      filter: true,
    },
    {
      field: 'identifier',
      headerName: t('pages.fundManager.financialFlows.table.identifier'),
      enableRowGroup: false,
      filter: true,
    },
    {
      field: 'drawdown',
      headerName: t('pages.fundManager.financialFlows.table.drawdown'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'distributed',
      headerName: t('pages.fundManager.financialFlows.table.distributed'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'recallableDistributed',
      headerName: t(
        'pages.fundManager.financialFlows.table.recallableDistributed',
      ),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'status',
      headerName: t('pages.fundManager.financialFlows.table.status'),
      enableRowGroup: false,
      filter: true,
    },
  ];
};
