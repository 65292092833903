import { Stack } from '@mui/material';
import { RowClickedEvent } from 'ag-grid-community';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { getFundAggregatedSubscriptionsData } from 'business/fund-manager/fund/services/get-fund-aggregated-subscriptions-data';
import { getFundSubscriptionCustomFilters } from 'business/fund-manager/fund/services/get-fund-subscription-custom-filters';
import { getFundSubscriptionListColumnDefinition } from 'business/fund-manager/fund/services/get-fund-subscriptions-list-column-definition';
import { FundCommitmentAndOperations } from 'business/fund-manager/fund/services/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { SubscriptionHistoryDrawer } from 'business/shared/components/subscription-history-drawer/subscription-history-drawer';
import { GetCommitmentAndOperationForFundQueryResult } from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { Typo } from 'ui/typo';

interface SubscriptionsTableProps {
  loading: boolean;
  data: GetCommitmentAndOperationForFundQueryResult['data'];
}
export const SubscriptionsTable = ({
  loading,
  data,
}: SubscriptionsTableProps) => {
  const { t } = useTranslation();
  const { currency } = useFund();

  const [selectedRow, setSelectedRow] = useState<
    { shareId: string; lpId: string } | undefined
  >(undefined);

  const computedData = data
    ? getFundAggregatedSubscriptionsData(
        data.getCommitmentsAndOperationsForFund.data,
        currency,
      )
    : [];

  const customFilters = getFundSubscriptionCustomFilters(computedData);
  const headers = getFundSubscriptionListColumnDefinition(t, customFilters);

  const onRowClick = (event: RowClickedEvent<FundCommitmentAndOperations>) => {
    if (!event.data?.shareId || !event.data?.lpId) {
      return;
    }
    setSelectedRow({
      shareId: event.data?.shareId,
      lpId: event.data?.lpId,
    });
  };

  const onCloseHistory = () => {
    setSelectedRow(undefined);
  };

  return (
    <Stack spacing={2}>
      <Typo size="xl" color="primary">
        {t('pages.fundManager.subscription.table.titles.validated')}
      </Typo>
      <AlternativeTable<FundCommitmentAndOperations>
        fileName="fund-subscriptions-list"
        rowData={computedData}
        columnDefs={headers}
        domLayout="autoHeight"
        loading={loading}
        displaySidebar
        onRowClicked={onRowClick}
      />
      {selectedRow ? (
        <SubscriptionHistoryDrawer
          lpId={selectedRow.lpId}
          shareId={selectedRow.shareId}
          open={!!selectedRow}
          onClose={onCloseHistory}
        />
      ) : null}
    </Stack>
  );
};
