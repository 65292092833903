import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationNavBar } from 'business/fund-manager/operation/components/operation-nav-bar';
import { getOperationEmailingColumnDefinition } from 'business/fund-manager/operation/services/get-operation-emailing-column-definition';
import { getOperationEmailingStatus } from 'business/fund-manager/operation/services/get-operation-emailing-status';
import {
  OperationNavBarTabEnum,
  OperationShareLpEmailing,
} from 'business/fund-manager/operation/services/types';
import {
  useOperationEmailingStatusByShareLpQuery,
  useResetOperationShareLpErrorStatusMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

const OperationEmailingContent = () => {
  const { operationId = '' } = useParams();
  const { t } = useTranslation();
  const { data, loading, error } = useOperationEmailingStatusByShareLpQuery({
    variables: {
      input: { operationId },
    },
  });

  const [
    resetOperationShareLpErrorStatus,
    { loading: resetOpeShareLpStatusLoading },
  ] = useResetOperationShareLpErrorStatusMutation();

  if (!data || loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const resetOpeShareLpStatus = (id: string) =>
    resetOperationShareLpErrorStatus({
      variables: { input: { operationShareLpId: id } },
    });

  const rows = data.operationEmailingStatusByShareLp.map(
    (operationShareLp): OperationShareLpEmailing => {
      const { status, statusCode } = getOperationEmailingStatus({
        t,
        waitingForGeneration: operationShareLp.waitingForGeneration,
        hasGenerationError: operationShareLp.hasGenerationError,
        errorCode: operationShareLp.errorCode,
        hasEmailSent: operationShareLp.email?.sentAt,
      });

      return {
        id: operationShareLp.id,
        lpName: operationShareLp.lpView.lpName,
        shareName: operationShareLp.operationShare.share.name,
        shouldEmailLetter: operationShareLp.shouldEmailLetter,
        status: status,
        statusCode: statusCode,
        emailSentAt: operationShareLp.email?.sentAt
          ? formatAsDate(operationShareLp.email?.sentAt)
          : '',
        emailReceivers: operationShareLp.email?.receivers ?? [''],
      };
    },
  );

  const headers = getOperationEmailingColumnDefinition({
    t,
    resetOpeShareLpStatus,
    resetOpeShareLpStatusLoading,
  });

  return (
    <AlternativeTable<OperationShareLpEmailing>
      rowData={rows}
      columnDefs={headers}
      loading={loading}
      fileName="operation-emailing"
      domLayout="autoHeight"
    />
  );
};

export const OperationEmailing = () => {
  return (
    <OperationNavBar activeTab={OperationNavBarTabEnum.Emailing}>
      <OperationEmailingContent />
    </OperationNavBar>
  );
};
