import { getLpContactRoles } from 'business/shared/lp-creation-module/services/get-lp-contact-roles';
import { ValidationEntityTypeMultiple } from 'ui/validation-card';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

import { getContactItems } from './get-contact-items';
import { GetCardItemsParams, LpValidationLegalEntity } from './types';

export const getContactsList = ({
  data,
  t,
  language,
  withRoles = true,
}: Omit<
  GetCardItemsParams<LpValidationLegalEntity['lpContacts']>,
  'declineReasons'
> & { withRoles?: boolean }): ValidationEntityTypeMultiple => ({
  type: 'multiple',
  entities: data.map(({ contactIndividualEntity, ...rest }) => ({
    items: [
      ...getContactItems({ t, language, data: contactIndividualEntity }),
      ...(withRoles
        ? ([
            {
              type: VALIDATION_ITEM_TYPES.TEXT,
              label: t('components.lpContactRole.label'),
              value: getLpContactRoles(rest)
                .map((role) => t('components.lpContactRole', { context: role }))
                .join(', '),
            },
          ] as ValidationItemType[])
        : ([] as ValidationItemType[])),
    ],
  })),
});
