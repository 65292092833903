import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';

import { LpSubscription, LpSubscriptionFilteredFields } from './types';

type GetLpPendingSubscriptionsListColumnDefinitionParams = {
  t: TFunction;
  handleValidateSubscription: (subscriptionId: string, fundId: string) => void;
  customFilters: LpSubscriptionFilteredFields;
  shouldShowValidationButton: boolean;
};

export const getLpPendingSubscriptionsListColumnDefinition = ({
  t,
  handleValidateSubscription,
  customFilters,
  shouldShowValidationButton,
}: GetLpPendingSubscriptionsListColumnDefinitionParams): ColDef<LpSubscription>[] => {
  const columns: ColDef<LpSubscription>[] = [
    {
      field: 'fundName',
      headerName: t('pages.fundManager.subscription.table.fundName'),
      filter: true,
      filterParams: customFilters.fundNames,
    },
    {
      field: 'shareType',
      headerName: t('pages.fundManager.subscription.table.shareName'),
      filter: true,
      filterParams: customFilters.shares,
    },
    {
      field: 'numericAmount',
      headerName: t('pages.fundManager.subscription.table.amount'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'date',
      headerName: t('pages.fundManager.subscription.table.date'),
    },
  ];

  if (shouldShowValidationButton) {
    columns.push({
      headerName: t('pages.fundManager.subscription.table.validation'),
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({
        data,
      }: ICellRendererParams<NonNullable<LpSubscription>>) => {
        if (!data) {
          return;
        }

        return (
          <ActionButton
            variant="submit"
            onClick={() => handleValidateSubscription(data.id, data.fundId)}
          >
            {t('pages.fundManager.subscription.table.actionButton.validate')}
          </ActionButton>
        );
      },
    });
  }

  return columns;
};
