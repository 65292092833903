import { Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { LpOnboardingDocument } from 'business/fund-manager/fund/services/types';
import { WebDocumentDownloadButton } from 'business/fund-manager/shared/components/web-download-button';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { DocumentStatus } from 'ui/document-status';

type Params = {
  t: TFunction;
  documentStatusClassName?: string;
};

export const getLpOnboardingDocumentsColumnDefinition = ({
  t,
  documentStatusClassName,
}: Params): ColDef<LpOnboardingDocument>[] => [
  {
    field: 'name',
    headerName: t(
      'pages.fundManager.lpCreation.form.KYCDocumentation.table.headers.name',
    ),
  },
  {
    field: 'date',
    headerName: t(
      'pages.fundManager.lpCreation.form.KYCDocumentation.table.headers.date',
    ),
  },
  {
    field: 'type',
    headerName: t(
      'pages.fundManager.lpCreation.form.KYCDocumentation.table.headers.type',
    ),
  },
  {
    field: 'status',
    headerName: t(
      'pages.fundManager.lpCreation.form.KYCDocumentation.table.headers.status',
    ),
    cellRenderer: ({ data }: { data: LpOnboardingDocument }) => {
      return (
        <DocumentStatus
          status={data.status}
          text={t(
            `pages.fundManager.lpCreation.form.KYCDocumentation.status.${data.status}`,
          )}
          className={documentStatusClassName}
        />
      );
    },
  },
  {
    field: 'expirationDate',
    headerName: t(
      'pages.fundManager.lpCreation.form.KYCDocumentation.table.headers.expirationDate',
    ),
  },
  {
    headerName: t(
      'pages.fundManager.lpCreation.form.KYCDocumentation.table.headers.actions.title',
    ),
    flex: 1,
    type: AgGridColumnTypesEnum.INTERACTION,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    cellRenderer: ({ data }: { data: LpOnboardingDocument }) => {
      return (
        <Stack direction="row" spacing={1}>
          {/* TODO: change DownloadButton with a view button (see figma) once we can preview a PDF */}
          <WebDocumentDownloadButton documentIds={[data.documentId]} />
          {/* NOTE: we are not gonna EDIT a document - no edit button (comment to clean once feature is completed) */}
          {/* // TODO: archive button */}
        </Stack>
      );
    },
  },
];
