import { Stack } from '@mui/material';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { AddressFormPart } from 'business/shared/components/address-form-part';
import { LpContactType } from 'business/shared/lp-creation-module/services/types';
import { ContactTypeEnum, LegalEntityKycRole } from 'generated/graphql';
import { getFieldName } from 'technical/get-field-name';
import { ContactIdentityForm } from 'ui/contact-form/contact-identity-form';
import {
  FormDropdownCountry,
  FormInputDate,
  FormInputText,
  FormSection,
  SelectInput,
} from 'ui/form';
import { FormInputPhone } from 'ui/form/form-input-phone/form-input-phone';
import { LpLegalEntityFormPart } from 'ui/lp-legal-entity-form-part';

import { franceDepartments } from './constants';

interface BirthDepartmentInputProps {
  control?: Control<any>;
  name?: string;
}
const BirthDepartmentInput = ({ control, name }: BirthDepartmentInputProps) => {
  const { t } = useTranslation();

  const country = useWatch({
    name: `${name}.birthCountry`,
    control,
  });

  return country === 'FR' ? (
    <SelectInput
      label={t('pages.contact.form.label.birthDepartment')}
      id="birthDepartment"
      name={getFieldName<LpContactType['contact']>('birthDepartment', name)}
      fullWidth
      options={franceDepartments}
    />
  ) : (
    <FormInputText
      id="birthDepartment"
      name={getFieldName<LpContactType['contact']>('birthDepartment', name)}
      label={t('pages.contact.form.label.birthDepartment')}
      fullWidth
    />
  );
};

interface IndividualFormPartProps {
  control?: Control<any>;
  name?: string;
  isAddressRequired?: boolean;
}
const IndividualFormPart = ({
  control,
  name,
  isAddressRequired,
}: IndividualFormPartProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const roles = watch('roles');

  const isSignatory = roles?.includes(LegalEntityKycRole.Signatory);
  const isExecutive = roles?.includes(LegalEntityKycRole.Executive);

  return (
    <>
      <FormInputPhone
        id="phoneNumber1"
        name={getFieldName<LpContactType['contact']>('phoneNumber1', name)}
        label={t('pages.contact.form.label.mobile')}
        control={control}
        fullWidth
      />
      <Stack spacing={2} direction={'row'}>
        <FormInputText
          id="jobTitle"
          fullWidth
          name={getFieldName<LpContactType['contact']>('jobTitle', name)}
          label={t('pages.contact.form.label.function')}
          control={control}
        />
        {isSignatory || isExecutive ? (
          <FormInputDate
            name={getFieldName<LpContactType['contact']>('jobEndDate', name)}
            label={t('pages.contact.form.label.jobEndDate')}
            fullWidth
          />
        ) : null}
      </Stack>
      <FormInputText
        id="taxpayerIdentificationNumber"
        fullWidth
        name={getFieldName<LpContactType['contact']>(
          'taxpayerIdentificationNumber',
          name,
        )}
        label={t('pages.contact.form.label.taxpayerIdentificationNumber')}
      />
      <FormInputDate
        name={getFieldName<LpContactType['contact']>('birthDate', name)}
        label={t('pages.contact.form.label.birthDate')}
        fullWidth
      />
      <Stack spacing={2} direction="row" justifyItems="stretch">
        <FormInputText
          id="birthCity"
          name={getFieldName<LpContactType['contact']>('birthCity', name)}
          label={t('pages.contact.form.label.birthCity')}
          fullWidth
        />
        <BirthDepartmentInput control={control} name={name} />
        <FormDropdownCountry
          label={t('pages.contact.form.label.birthCountry')}
          name={getFieldName<LpContactType['contact']>('birthCountry', name)}
          fullWidth
        />
      </Stack>

      <FormSection title={t('pages.contact.form.section.address')}>
        <AddressFormPart
          control={control}
          name="address"
          required={isAddressRequired}
        />
      </FormSection>
    </>
  );
};

interface LegalFormPartProps {
  control?: Control<any>;
  name?: string;
}
const LegalFormPart = ({ control, name }: LegalFormPartProps) => {
  const { t } = useTranslation();
  return (
    <>
      <FormInputText
        id="jobTitle"
        fullWidth
        name={getFieldName<LpContactType['contact']>('jobTitle', name)}
        label={t('pages.contact.form.label.function')}
        control={control}
      />

      <FormSection
        title={t(
          'pages.fundManager.lpCreation.form.generalInformation.client.legalEntity',
        )}
      >
        <LpLegalEntityFormPart
          control={control}
          name={getFieldName('legalEntity', name)}
        />
      </FormSection>
    </>
  );
};

interface LpContactFormPartProps {
  control?: Control<any>;
  name?: string;
  isAddressRequired?: boolean;
  type?: ContactTypeEnum;
}
export const LpContactFormPart = ({
  control,
  name,
  isAddressRequired,
  type = ContactTypeEnum.Individual,
}: LpContactFormPartProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <ContactIdentityForm<LpContactType> control={control} name={name} />
      <FormInputText
        id="email"
        name={getFieldName<LpContactType['contact']>('email', name)}
        label={t('pages.contact.form.label.email')}
        type="email"
        required
        control={control}
      />
      {type === ContactTypeEnum.Individual ? (
        <IndividualFormPart
          name={name}
          control={control}
          isAddressRequired={isAddressRequired}
        />
      ) : (
        <LegalFormPart name={name} control={control} />
      )}
    </Stack>
  );
};
