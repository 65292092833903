import { ICellRendererParams } from 'ag-grid-community';

import { formatAsDate, isISODateString } from 'technical/date';

export const DateCell = (params: ICellRendererParams) => {
  if (!params.value) {
    return null;
  }
  const value = isISODateString(params.value)
    ? params.value
    : formatAsDate(params.value);

  return <>{value}</>;
};
