import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { getLpContactRoles } from 'business/shared/lp-creation-module/services/get-lp-contact-roles';
import { ContactWithId } from 'business/shared/services/contact/types';
import { ContactTypeEnum, useLpContactsQuery } from 'generated/graphql';
import { parseDateFromStringUsingTimezone } from 'technical/date';

interface UseLpContacts {
  lpId: string;
  readOnlyForRoles?: boolean;
}
export const useLpContacts = ({ lpId, readOnlyForRoles }: UseLpContacts) => {
  const isOnboardingUser = useIsLpOnboardingUser();
  const { loading, error, data, refetch } = useLpContactsQuery({
    variables: {
      lpId,
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  const contacts: ContactWithId[] =
    data?.lpContacts?.map((information) => {
      const roles = getLpContactRoles(information);
      const { contactIndividualEntity: contactData } = information;
      return {
        id: contactData?.contactId ?? '',
        type: contactData?.contact?.type ?? ContactTypeEnum.Individual,
        firstName: contactData?.firstName ?? '',
        lastName: contactData?.lastName ?? '',
        email: contactData?.email ?? '',
        title: contactData?.title ?? undefined,
        phoneNumber1: contactData?.phoneNumber1 ?? undefined,
        phoneNumber2: contactData?.phoneNumber2 ?? undefined,
        companyName: contactData?.companyName ?? undefined,
        jobTitle: contactData?.jobTitle ?? undefined,
        jobEndDate: contactData?.jobEndDate
          ? new Date(contactData?.jobEndDate)
          : undefined,
        birthDate: contactData?.birthDate
          ? parseDateFromStringUsingTimezone(contactData.birthDate)
          : undefined,
        birthCity: contactData?.birthCity ?? undefined,
        birthDepartment: contactData?.birthDepartment ?? undefined,
        birthCountry: contactData?.birthCountry ?? undefined,
        taxpayerIdentificationNumber:
          contactData?.taxpayerIdentificationNumber ?? undefined,
        hasLpPortalAccess: information.hasLpPortalAccess ?? false,
        hasOperationalRights: information.hasOperationalRights ?? false,
        canAccessGeneralReports: information.canAccessGeneralReports ?? false,
        canAttendInvestorsMeeting:
          information.canAttendInvestorsMeeting ?? false,
        canAccessESGReports: information.canAccessESGReports ?? false,
        canAccessAmpereReports: information.canAccessAmpereReports ?? false,
        canAccessCapitalAccountStatement:
          information.canAccessCapitalAccountStatement ?? false,
        address: {
          streetLine: contactData?.address?.streetLine ?? undefined,
          streetLine2: contactData?.address?.streetLine2 ?? undefined,
          city: contactData?.address?.city ?? undefined,
          country: contactData?.address?.country ?? undefined,
          zipCode: contactData?.address?.zipCode ?? undefined,
        },
        isLegalRepresentative: information.isLegalRepresentative ?? false,
        roles,
        ultimateEffectiveControlPercentage:
          information.ultimateEffectiveControlPercentage,
        readOnly: roles.length > 0 && readOnlyForRoles,
        legalEntity: contactData?.legalEntity
          ? {
              subscribingEntityName:
                contactData.legalEntity.subscribingEntityName,
              uniqueCompanyIdentifier:
                contactData.legalEntity.uniqueCompanyIdentifier,
              registrationLocation:
                contactData.legalEntity.registrationLocation,
              country: contactData.legalEntity.country,
            }
          : undefined,
        canDelete:
          !isOnboardingUser &&
          !information.isLegalRepresentative &&
          !information.isUltimateBeneficialOwner,
      };
    }) ?? [];

  return { loading, error, refetch, contacts };
};
