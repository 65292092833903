import {
  CurrencyEnum,
  GetCommitmentAndOperationForFundQuery,
} from 'generated/graphql';
import { addNumbersOrNull } from 'technical/number/add-numbers-or-null';

import { FundCommitmentAndOperations } from './types';

export const getFundAggregatedSubscriptionsData = (
  commitments: GetCommitmentAndOperationForFundQuery['getCommitmentsAndOperationsForFund']['data'],
  currency: CurrencyEnum,
): FundCommitmentAndOperations[] => {
  return commitments.map((commitment) => {
    return {
      numericAmount: commitment?.numericAmount ?? 0,
      lpName: commitment?.lpView?.lpName ?? '',
      lpId: commitment?.lpView?.id ?? '',
      shareName: commitment?.share?.name ?? '',
      shareId: commitment?.share?.id ?? '',
      status: commitment?.status,
      numericDrawn: addNumbersOrNull([
        commitment?.numericInvestment,
        commitment?.numericFees,
        commitment?.numericOther,
      ]),
      numericCurrentDistributed: commitment?.numericCurrentDistributed,
      numericDistributedNonRecallable: addNumbersOrNull([
        commitment?.numericCapitalGain,
        commitment?.numericDividend,
        commitment?.numericInterest,
        commitment?.numericReturnOfCost,
      ]),
      currency,
    };
  });
};
