import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatAsDate } from 'technical/date';
import { ValidationButton } from 'ui/validation-button';

import { CashflowRow } from './types';

type GetCashflowColumnDefsParams = {
  t: TFunction;
  shouldHaveValidationAction?: boolean;
  handleValidation?: (id: string) => void;
};

export function getCashflowColumnDefs({
  t,
  shouldHaveValidationAction,
  handleValidation,
}: GetCashflowColumnDefsParams): ColDef<CashflowRow>[] {
  const columns: ColDef<CashflowRow>[] = [
    {
      field: 'date',
      headerName: t('pages.fundManager.investorCashflows.table.date'),
      valueFormatter: ({ value }) => (value ? formatAsDate(value) : ''),
    },
    {
      field: 'type',
      headerName: t('pages.fundManager.investorCashflows.table.flowType'),
      valueFormatter: ({
        value,
      }: ValueFormatterParams<CashflowRow, string>) => {
        return t('pages.fundManager.investorCashflows.table.flowType', {
          context: value,
        });
      },
    },
    {
      field: 'paidOut',
      headerName: t('pages.fundManager.investorCashflows.table.paidOut'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'paidIn',
      headerName: t('pages.fundManager.investorCashflows.table.paidIn'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
  ];

  if (shouldHaveValidationAction && handleValidation) {
    columns.push({
      headerName: t('pages.fundManager.investorCashflows.table.validation'),
      type: AgGridColumnTypesEnum.INTERACTION,
      colId: 'interaction',
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (!value) {
          return null;
        }

        return (
          <ValidationButton
            onClick={() => handleValidation(value)}
            color="validate"
          >
            {t('pages.fundManager.investorCashflows.table.validate')}
          </ValidationButton>
        );
      },
      valueGetter: ({ data }) => {
        if (!data) {
          return null;
        }

        return data.id;
      },
    });
  }

  return columns;
}
