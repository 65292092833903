import { Outlet, RouteObject } from 'react-router';

import { LpDiligenceTabs } from 'business/fund-manager/lp/components/diligence-tabs';
import { LpDetailPage } from 'business/fund-manager/lp/components/lp-details-page';
import { LpEditionTabs } from 'business/fund-manager/lp/components/lp-edition-tabs';
import { Contacts } from 'business/fund-manager/lp/pages/contacts';
import { LpDashboard } from 'business/fund-manager/lp/pages/dashboard';
import { LpFinancialFlowPage } from 'business/fund-manager/lp/pages/financial-flow';
import { LpKYB } from 'business/fund-manager/lp/pages/legal-entity-kyb';
import { LpKycKybAutocompleteUpdatePage } from 'business/fund-manager/lp/pages/legal-entity-kyc-kyb-autocomplete/legal-entity-kyc-kyb-autocomplete';
import { LpAmlRiskAssessmentPage } from 'business/fund-manager/lp/pages/lp-aml-risk-assessment-page/lp-aml-risk-assessment-page';
import { LpDocumentList } from 'business/fund-manager/lp/pages/lp-document';
import { LpEdit } from 'business/fund-manager/lp/pages/lp-edit/lp-edit';
import { LpEscrowAccount } from 'business/fund-manager/lp/pages/lp-escrow-account';
import { LpFundBankDetails } from 'business/fund-manager/lp/pages/lp-fund-bank-details';
import { LpKYC } from 'business/fund-manager/lp/pages/lp-kyc';
import { LpKYCDocumentation } from 'business/fund-manager/lp/pages/lp-kyc-documentation';
import { FundManagerLps } from 'business/fund-manager/lp/pages/lp-list';
import { LpSubscriptionList } from 'business/fund-manager/lp/pages/lp-subscription-list';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { getLpCreationRoutes } from 'business/shared/lp-creation-module';

const lpCreationRoutes = getLpCreationRoutes({
  basename: FundManagerRoutes.LpCreate,
  previousPath: FundManagerRoutes.Lp,
  editPath: FundManagerRoutes.LpId,
});

export const lpRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.Lp,
    element: <FundManagerLps />,
  },
  {
    path: FundManagerRoutes.LpCreate,
    children: lpCreationRoutes,
  },
  {
    path: FundManagerRoutes.LpId,
    element: (
      <LpDetailPage>
        <Outlet />
      </LpDetailPage>
    ),
    children: [
      {
        path: FundManagerRoutes.LpId,
        element: <LpDashboard />,
      },
      {
        path: FundManagerRoutes.LpIdContacts,
        element: <Contacts />,
      },
      {
        path: FundManagerRoutes.LpIdEdit,
        element: (
          <LpEditionTabs>
            <Outlet />
          </LpEditionTabs>
        ),
        children: [
          {
            path: FundManagerRoutes.LpIdEditProfile,
            element: <LpEdit />,
          },
          {
            path: FundManagerRoutes.LpIdEditBankDetails,
            element: <LpFundBankDetails />,
          },
        ],
      },
      {
        path: FundManagerRoutes.LpIdDiligence,
        element: (
          <LpDiligenceTabs>
            <Outlet />
          </LpDiligenceTabs>
        ),
        children: [
          {
            path: FundManagerRoutes.LpIdDiligenceAmlRiskAssessment,
            element: <LpAmlRiskAssessmentPage />,
          },
          {
            path: FundManagerRoutes.LpIdDiligenceKYC,
            element: <LpKYC />,
          },
          {
            path: FundManagerRoutes.LpIdDiligenceLegalEntityKyb,
            element: <LpKYB />,
          },
          {
            path: FundManagerRoutes.LpIdDiligenceKYCDocumentation,
            element: <LpKYCDocumentation />,
          },
          {
            path: FundManagerRoutes.LpIdDiligenceKYCKYBAutocompleteUpdate,
            element: <LpKycKybAutocompleteUpdatePage />,
          },
        ],
      },
      {
        path: FundManagerRoutes.LpIdFinancialFlow,
        element: <LpFinancialFlowPage />,
      },

      {
        path: FundManagerRoutes.LpIdEscrowAccount,
        element: <LpEscrowAccount />,
      },
      {
        path: FundManagerRoutes.LpIdSubscription,
        element: <LpSubscriptionList />,
      },
      {
        path: FundManagerRoutes.LpIdDocument,
        element: <LpDocumentList />,
      },
    ],
  },
];
