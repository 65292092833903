import { LpInvestorTypeEnum, LpKybFormDataQuery } from 'generated/graphql';
import { convertFromX100toNumber } from 'technical/currency/formatters';
import { legalFormsByCountryCode } from 'ui/form/select-legal-form-input/legal-forms-by-country-code';

import { LpKYB } from './types';

const getValidLegalFormValue = ({
  value,
  country,
}: {
  value?: string;
  country: string;
}) => {
  if (!value || !legalFormsByCountryCode?.[country]) {
    return undefined;
  }

  const found = legalFormsByCountryCode?.[country].findIndex(
    (v) => v.id === value,
  );
  return found > -1 ? value : undefined;
};

export const getKYBFormValues = (
  data: NonNullable<LpKybFormDataQuery>,
): LpKYB => {
  const { kyb: kybValues, lp } = data;
  if (kybValues) {
    return {
      // needed because there is a conflict between LpInvestorTypeEnum and Web_Portal_LpInvestorType_Enum while they have same content !
      // TODO: find another more proper way to handle that when possible
      investorType:
        (kybValues.investorType as unknown as LpInvestorTypeEnum) ??
        LpInvestorTypeEnum.Institutional,
      professionnalClient: kybValues.professionnalClient ?? false,
      USRelatedPerson: kybValues.USRelatedPerson ?? false,
      frenchEquitySavingsPlan: kybValues.frenchEquitySavingsPlan ?? false,
      taxOption: kybValues.taxOption ?? false,
      ampereReporting: kybValues.ampereReporting ?? false,
      lpNaceCode: kybValues.lpNaceCode ?? undefined,
      corporatePurpose: kybValues.corporatePurpose ?? undefined,
      headOfficeUniqueCompanyIdentifier:
        kybValues.headOfficeUniqueCompanyIdentifier ?? undefined,
      headOfficeNaceCode: kybValues.headOfficeNaceCode ?? undefined,
      headOfficeHeadcount: kybValues.headOfficeHeadcount ?? undefined,
      vatIdentificationNumber: kybValues.vatIdentificationNumber ?? undefined,
      investorGroup: kybValues.investorGroup ?? undefined,
      legalForm: getValidLegalFormValue({
        value: kybValues.legalForm ?? '',
        country: lp?.lpLegalEntityGeneralInfos?.[0]?.country ?? '',
      }),
      endOfFiscalYear: kybValues.endOfFiscalYear
        ? new Date(kybValues.endOfFiscalYear)
        : undefined,
      headcount: kybValues.headcount ?? undefined,
      companyCapital: kybValues.companyCapital
        ? convertFromX100toNumber(kybValues.companyCapital)
        : undefined,
      currency: kybValues.currency ?? undefined,
      lei: kybValues.lei ?? '',
    };
  }

  return {
    legalForm: undefined,
    investorType: LpInvestorTypeEnum.Institutional,
    professionnalClient: false,
    USRelatedPerson: false,
    frenchEquitySavingsPlan: false,
    taxOption: false,
    ampereReporting: false,
    corporatePurpose: '',
    headOfficeUniqueCompanyIdentifier: '',
    headOfficeNaceCode: '',
    headOfficeHeadcount: '',
    vatIdentificationNumber: '',
    // unfortunately it is a date value
    // so we can't pass it an empty string
    endOfFiscalYear: undefined,
    headcount: '',
    companyCapital: 0,
    currency: '',
  };
};
