import { isDefined } from 'technical/is-defined';
import { removeDuplicates } from 'technical/remove-duplicates';

import {
  FundCommitmentAndOperations,
  FundSubscriptionsFilteredFields,
} from './types';

export function getFundSubscriptionCustomFilters(
  data: FundCommitmentAndOperations[],
): FundSubscriptionsFilteredFields {
  if (!data) {
    return {
      lpNames: [],
      shareNames: [],
    };
  }

  const mappedLps = data
    .map((commitment) => {
      if (commitment?.lpName) {
        return { lpName: commitment.lpName };
      }
      return null;
    })
    .filter(isDefined);

  const mappedShares = data
    .map((commitment) => {
      if (commitment.shareName) {
        return { shareName: commitment.shareName };
      }
      return null;
    })
    .filter(isDefined);

  return {
    lpNames: removeDuplicates(mappedLps, 'lpName'),
    shareNames: removeDuplicates(mappedShares, 'shareName'),
  };
}
