import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CreateValuationProceedFormInput } from 'business/fund-manager/portfolio/valuation/services/types';
import { createValuationProceedFormSchema } from 'business/fund-manager/portfolio/valuation/services/validation';
import { validateDate } from 'business/fund-manager/portfolio/valuation/services/valuation-instrument-proceed-validate-date';
import { useCreateValuationProceedMutation } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { FormInputDate, FormInputText } from 'ui/form';

interface Props {
  companyInstrumentId: string;
  onCompleted: () => void;
  valuationCreationDate: Date | undefined;
}

export const CompanyInstrumentProceedForm = ({
  companyInstrumentId,
  onCompleted,
  valuationCreationDate,
}: Props) => {
  const { t } = useTranslation();

  const { handleSubmit, control, setError, clearErrors, reset } =
    useForm<CreateValuationProceedFormInput>({
      resolver: yupResolver(createValuationProceedFormSchema),
    });

  const [createValuationProceed, { loading, error }] =
    useCreateValuationProceedMutation({
      onCompleted: () => {
        onCompleted();
      },
    });

  const onSubmit = async (input: CreateValuationProceedFormInput) => {
    const { proceed, date } = input;

    // We need verify the validity of the selected date based on the received initial values.
    const isValidDate = validateDate({
      date,
      valuationCreationDate,
      setError,
      clearErrors,
      t,
    });

    if (!isValidDate) {
      return;
    }

    await createValuationProceed({
      variables: {
        input: {
          companyInstrumentId,
          proceed,
          date,
        },
      },
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack justifyContent="flex-start" spacing={5}>
        <Stack direction="row" justifyContent="space-around">
          <FormInputDate
            name="date"
            control={control}
            label={t(
              'pages.fundManager.portfolio.valuationInstrumentProceed.transaction_date',
            )}
            required
            disabled={loading}
          />
          <FormInputText
            name="proceed"
            label={t(
              'pages.fundManager.portfolio.valuationInstrumentProceed.amount',
            )}
            control={control}
            required
          />
          <Stack>
            <ActionButton variant="submit" type="submit">
              {t('common.actions.add')}
            </ActionButton>
            {error ? <ErrorLabel label={error.message} /> : null}
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
