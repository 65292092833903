import { Stack } from '@mui/material';
import { FormProvider, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import { LpProfileForm } from 'business/shared/lp-creation-module/components/lp-profile-form';
import { getCreationStepDetails } from 'business/shared/lp-creation-module/services/get-creation-steps-details';
import { useCreateLpGeneralProfileForm } from 'business/shared/lp-creation-module/services/hooks/use-create-lp-general-profile-form';
import { useLpCreationModuleNavigationContext } from 'business/shared/lp-creation-module/services/hooks/use-lp-creation-module-navigation-context';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  ClientTypeEnum,
  LpCreationStepEnum,
  useCustomQuestionsQuery,
} from 'generated/graphql';
import { setFormCustomAnswersValue } from 'technical/custom-questions';
import { FormCard, FormWizzard } from 'ui/form';
import { CustomQuestionsFormPart } from 'ui/form/custom-form-part/custom-form-part';

export const LpCreationGeneralProfile = () => {
  const { previousPath } = useLpCreationModuleNavigationContext();
  const navigateInApp = useNavigate();

  const { methods, onSubmit, saving, error } = useCreateLpGeneralProfileForm();

  const clientType = useWatch({
    control: methods.control,
    name: 'client',
  });

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const displayLpInvestorProfileStep = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
  );

  const isLpPlatform = useIsLpPlatform();

  const { steps } = getCreationStepDetails({
    t,
    clientType,
    currentUrl: pathname,
    displayLpInvestorProfileStep,
    displayLpContactsStep: !isLpPlatform,
  });

  const hasActivatedCustomQuestions = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_CUSTOM_QUESTION,
  );

  const managementCompanyId = useWatch({
    name: 'managementCompanyId',
    control: methods.control,
  });

  const entityStep =
    clientType === ClientTypeEnum.Individual
      ? LpCreationStepEnum.IndividualGeneralInfos
      : LpCreationStepEnum.LegalEntityGeneralInfos;

  const { data, loading } = useCustomQuestionsQuery({
    skip: !hasActivatedCustomQuestions || managementCompanyId === '',
    fetchPolicy: 'network-only',
    variables: {
      input: {
        entityStep,
      },
    },
    onCompleted: (response) => {
      setFormCustomAnswersValue(
        { questions: response.customQuestions },
        methods.setValue,
      );
    },
  });

  return (
    <Stack padding={1}>
      <FormWizzard
        steps={steps}
        activeStep={0}
        onNext={!saving && !loading ? onSubmit : undefined}
        onPrevious={() => navigateInApp(previousPath)}
      />
      <FormCard>
        <FormProvider {...methods}>
          <LpProfileForm error={error} saving={saving} />
          {!!data?.customQuestions?.length ? (
            <CustomQuestionsFormPart items={data.customQuestions} />
          ) : null}
        </FormProvider>
      </FormCard>
    </Stack>
  );
};
