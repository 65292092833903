import { isAfter } from 'date-fns';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { TFunction } from 'translations/hook';

import { FormInputDateValue } from 'ui/form';

export interface ValidateDateArgs {
  date: FormInputDateValue;
  valuationCreationDate: Date | undefined;
  clearErrors: UseFormClearErrors<{
    proceed: number;
    date: Date;
  }>;
  setError: UseFormSetError<{
    date: Date;
    proceed: number;
  }>;
  t: TFunction;
}

export const isDateAfterValuationCreation = (
  date: Date,
  valuationCreationDate?: Date,
) => {
  return valuationCreationDate && isAfter(date, valuationCreationDate);
};

export const validateDate = ({
  date,
  valuationCreationDate,
  clearErrors,
  setError,
  t,
}: ValidateDateArgs) => {
  if (!date) {
    return;
  }
  clearErrors('date');

  if (isDateAfterValuationCreation(date, valuationCreationDate)) {
    setError('date', {
      type: 'manual',
      message: t(
        'pages.fundManager.portfolio.valuationInstrumentProceed.form.errors.date_inferior_than_valuation_creation_date',
      ),
    });
    return false;
  }

  return true;
};
