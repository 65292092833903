import {
  formatISO as dateFnsFormatIso,
  format,
  isValid,
  parse,
  subDays,
} from 'date-fns';
import { toDate } from 'date-fns-tz';

/**
 * Convert a datetime to an ISO date string
 * If the datetime is already an ISO date string, it will be returned as is.
 * Otherwise, it will be cast to a Date object and displayed as an ISO date string (timezone adjustments will apply).
 */
// TODO PPDS à renommer en convertDatetimeToISODateString
export function formatAsDate(value: Date | string | number) {
  if (isISODateString(value as string)) {
    return value as string;
  }
  const date = typeof value === 'string' ? new Date(value) : value;
  if (date && isValid(date)) {
    return dateFnsFormatIso(date, { representation: 'date' });
  }
  return '';
}

export const formatISO = (date: Date | number) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const parseDateFromStringUsingTimezone = (dateString: string) => {
  const date = toDate(dateString, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return isValid(date) ? date : undefined;
};

export function parseDateString(dateString: string) {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());

  return isValid(date) ? date : undefined;
}

export const getNinetyDaysAgo = () => {
  const today = new Date();
  return subDays(today, 90).toISOString();
};

/**
 * Create a local timezone date from a ISO date string
 */
export function convertFromISOdateStringToLocalDateTz(date: string) {
  const dateParts = date.split('-');
  return new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[2]),
  );
}

export function isISODateString(date: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(date);
}
