import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

import {
  FundCommitmentAndOperations,
  FundSubscriptionsFilteredFields,
} from './types';

export function getFundSubscriptionListColumnDefinition(
  t: TFunction,
  customFilters: FundSubscriptionsFilteredFields,
): ColDef<FundCommitmentAndOperations>[] {
  const columns: ColDef<FundCommitmentAndOperations>[] = [
    {
      headerName: t('pages.fundManager.subscription.table.lpName'),
      field: 'lpName',
      filter: true,
      filterParams: customFilters.lpNames,
    },
    {
      headerName: t('pages.fundManager.subscription.table.shareName'),
      field: 'shareName',
      filter: true,
      filterParams: customFilters.shareNames,
    },
    {
      headerName: t('pages.fundManager.subscription.table.commitment'),
      field: 'numericAmount',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      headerName: t('pages.fundManager.subscription.table.unfundedCommitment'),
      colId: 'unfundedCommitment',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      valueGetter: ({ data }) => {
        if (!data?.numericAmount) {
          return 0;
        }
        if (!data?.numericDrawn) {
          return data?.numericAmount;
        }
        return (
          data?.numericAmount -
          data?.numericDrawn +
          (data?.numericCurrentDistributed ?? 0)
        );
      },
    },
    {
      headerName: t('pages.fundManager.subscription.table.paidIn'),
      field: 'numericDrawn',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      headerName: t(
        'pages.fundManager.subscription.table.distributedNonRecallable',
      ),
      field: 'numericDistributedNonRecallable',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      headerName: t('pages.fundManager.subscription.table.distributed'),
      field: 'numericCurrentDistributed',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
  ];

  return columns;
}
