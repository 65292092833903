import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';

import { ShareTransfer, ShareTransferFilteredFields } from './types';

type GetFundPendingValidationShareTransfersColumnDefinitionParams = {
  t: TFunction;
  handleValidateShareTransfer: (shareTransferId: string) => void;
  customFilters: ShareTransferFilteredFields;
  shoudlShowValidationButton: boolean;
};

export const getFundPendingValidationShareTransfersColumnDefinition = ({
  t,
  handleValidateShareTransfer,
  customFilters,
  shoudlShowValidationButton,
}: GetFundPendingValidationShareTransfersColumnDefinitionParams): ColDef<ShareTransfer>[] => {
  const columns: ColDef<ShareTransfer>[] = [
    {
      field: 'shareType',
      headerName: t('pages.fundManager.shareTransfer.table.share'),
      filter: true,
      filterParams: customFilters.shareTypes,
    },
    {
      field: 'sellerName',
      headerName: t('pages.fundManager.shareTransfer.table.lpSeller'),
      filter: true,
      filterParams: customFilters.sellerNames,
    },
    {
      field: 'buyerName',
      headerName: t('pages.fundManager.shareTransfer.table.lpBuyer'),
      filter: true,
      filterParams: customFilters.buyerNames,
    },
    {
      field: 'numericAmount',
      headerName: t('pages.fundManager.shareTransfer.table.amount'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'date',
      headerName: t('pages.fundManager.shareTransfer.table.date'),
    },
    {
      field: 'numericTotalPrice',
      headerName: t('pages.fundManager.shareTransfer.table.totalPrice'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
  ];

  if (shoudlShowValidationButton) {
    columns.push({
      headerName: t('pages.fundManager.shareTransfer.table.validation'),
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({ data }: { data: ShareTransfer }) => {
        return (
          <ActionButton
            variant={'submit'}
            onClick={() => handleValidateShareTransfer(data.id)}
          >
            {t('pages.fundManager.shareTransfer.table.actionButton.validate')}
          </ActionButton>
        );
      },
    });
  }

  return columns;
};
