import * as yup from 'yup';

import {
  addressSchema,
  bankDetailsSchema,
} from 'business/shared/services/validation';
import { CurrencyEnum, Web_Portal_Currency_Enum } from 'generated/graphql';

export enum SubscriptionsNavBarTabEnum {
  ValidatedSubscriptions = 0,
  PendingValidationSubscriptions = 1,
}

export enum FeatureFlagEnum {
  DISPLAY_GROSS_IRR = 'display_gross_irr',
  DISPLAY_HEAD_COUNT = 'display_head_count',
  DISPLAY_DASHBOARD_TVPI = 'display_dashboard_tvpi',
  DISPLAY_DASHBOARD_MOIC = 'display_dashboard_moic',
  DISPLAY_VALUATION_SYNTHESIS = 'display_valuation_synthesis',
  DISPLAY_PORTFOLIO = 'display_portfolio',
  DISPLAY_FUND_VALUATION = 'display_fund_valuation',
  DISPLAY_DOCUMENTATION = 'display_documentation',
  DISPLAY_LP_INVESTOR_PROFILE_STEP = 'display_lp_investor_profile_step',
  DISPLAY_LP_SANCTION_CHECK = 'display_lp_sanction_check',
  DISPLAY_RISK_ASSESSMENT = 'display_risk_assesment',
  DISPLAY_LP_PERFORMANCE = 'display_lp_performance',
  DISPLAY_SHARE_VALUATION = 'display_share_valuation',
  DISPLAY_KYB_AUTOCOMPLETION = 'display_KYB_autocompletion_lp_form',
  DISPLAY_OPEN_SANCTION = 'display_open_sanction',
  DISPLAY_REPORTING = 'display_reporting',
  DISPLAY_COMPANY_METRICS = 'display_company_metrics',
  DISPLAY_SIGNATURE = 'display_signature',
  DISPLAY_OPERATION_CARD = 'display_operation_card',
  DISPLAY_CUSTOM_QUESTION = 'display_custom_question',
  // MODULE
  MODULE_ONBOARDING_SUBSCRIPTION = 'module_onboarding_subscription',
  // ANALYTICS
  RECORD_USER_SESSION = 'record_user_session',
}

export type BankDetails = yup.InferType<typeof bankDetailsSchema>;

export type Address = yup.InferType<typeof addressSchema>;

export const getCurrencyEnumFromWebPortalEnum = (
  currency: Web_Portal_Currency_Enum,
) => {
  switch (currency) {
    case Web_Portal_Currency_Enum.Aud:
      return CurrencyEnum.Aud;
    case Web_Portal_Currency_Enum.Usd:
      return CurrencyEnum.Usd;
    case Web_Portal_Currency_Enum.Chf:
      return CurrencyEnum.Chf;
    case Web_Portal_Currency_Enum.Gbp:
      return CurrencyEnum.Gbp;
    case Web_Portal_Currency_Enum.Eur:
      return CurrencyEnum.Eur;
    default:
      return CurrencyEnum.Eur;
  }
};

export type ValuationData = {
  valuationDate: string;
  paidIn: number;
  distributedNonRecallable: number;
  distributedRecallable: number;
  residualValue: number;
  shareId: string;
  dpi: number;
  rvpi: number;
};

export type ValuationGraphData = {
  valuationDate: string;
  paidIn: number;
  distributed: number;
  distributedRecallable: number;
  residual: number;
  dpi: number;
  rvpi: number;
  tvpi?: number;
};

// Plotly graphs need to have an array by type of data, not an array of data per date
export type ValuationGraphDataByType = {
  valuationDates: string[];
  tvpiValues: number[];
  dpiValues: number[];
  rvpiValues: number[];
  paidInValues: number[];
  totalDistributedValues: number[];
  residualValues: number[];
  surmResidualAndTotalDistributedValues: number[];
  // valuationGraphDataByDate: for customData field that requires data per date
  valuationGraphDataByDate: {
    valuationDate: string;
    dpi: number;
    rvpi: number;
    tvpi: number;
    paidIn: number;
    totalDistributed: number;
    residual: number;
  }[];
};
