import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyTransactionUpdateInput } from 'business/fund-manager/portfolio/company/services/types';
import { companyTransactionUpdateInputSchema } from 'business/fund-manager/portfolio/company/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { FormatAsLocaleDate } from 'business/shared/services/format-local-date';
import {
  WebCompanyTransactionTypeEnum,
  useDeleteTransactionMutation,
  useEditTransactionMutation,
} from 'generated/graphql';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import {
  convertFromISOdateStringToLocalDateTz,
  formatAsDate,
} from 'technical/date';
import { ActionButton } from 'ui/action-button';
import { ModalHeader } from 'ui/custom-modal/header';
import { ErrorLabel } from 'ui/error-label';
import { OnErrorDisplaySnackbar } from 'ui/error-snackbar/error-snackbar';
import { FormInputDate, FormInputText, FormToggleButton } from 'ui/form';

import { PortfolioInstrumentTransaction } from './instrument-table';

interface Props {
  initialValues?: PortfolioInstrumentTransaction;
  handleClose: () => void;
  lastValuationDate?: string | null;
}

export const InstrumentTransactionEditionModal = ({
  initialValues,
  handleClose,
  lastValuationDate,
}: Props) => {
  const { t } = useTranslation();
  const methods = useForm<CompanyTransactionUpdateInput>({
    resolver: yupResolver(companyTransactionUpdateInputSchema),
    defaultValues: {
      id: initialValues?.id,
      date: convertFromISOdateStringToLocalDateTz(initialValues?.date),
      quantity: initialValues?.quantity,
      totalQuantity: initialValues?.totalQuantity,
      nominalValue: initialValues?.nominalValue,
      type: initialValues?.type,
    },
  });
  const refetchQueries = [
    'getPortfolioValuationDetails',
    'getPortfolioInventoryDetails',
  ];
  const [editTransaction, { loading, error }] = useEditTransactionMutation({
    refetchQueries,
    onCompleted: () => {
      handleClose();
      enqueueSnackbar(t('successMessage.updateCompanyTransaction'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: OnErrorDisplaySnackbar,
  });

  const { handleSubmit, control } = methods;

  const onSubmit = (input: CompanyTransactionUpdateInput) => {
    return editTransaction({
      variables: {
        input: {
          ...input,
          date: formatAsDate(input.date),
        },
      },
    }).catch(() => undefined);
  };

  const [deleteTransaction, { loading: loadingDelete, error: errorDelete }] =
    useDeleteTransactionMutation({
      refetchQueries,
      onCompleted: () => {
        handleClose();
        enqueueSnackbar(t('successMessage.deleteCompanyTransaction'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: (err: ApolloError) => {
        enqueueSnackbar(err.message, {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const handleClickDelete = useCallback(async () => {
    if (!initialValues?.id) {
      throw new Error(
        'pages.fundManager.fundShareValuationCreation.form.error-valuationNotFound',
      );
    }
    if (
      !confirm(
        t('pages.fundManager.portfolio.companyTransaction.deleteTransaction'),
      )
    ) {
      return;
    }

    deleteTransaction({
      variables: {
        id: initialValues?.id ?? '',
      },
    }).catch(() => undefined);
  }, [initialValues, t, deleteTransaction]);

  const nominalValue = useWatch({ name: 'nominalValue', control });
  const quantity = useWatch({ name: 'quantity', control });
  const type = useWatch({ name: 'type', control });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader
        title={t(
          'pages.fundManager.portfolio.companyTransaction.editTransaction',
        )}
        onClose={handleClose}
        labelAction={t('common.actions.edit')}
      />
      <FormProvider {...methods}>
        <Stack spacing={3} padding={4}>
          <FormInputDate
            name="date"
            label={t(
              'pages.fundManager.portfolio.companyTransaction.form.date',
            )}
            control={control}
            required
          />
          {lastValuationDate && (
            <Typography variant="body2" color="textSecondary">
              {t(
                'pages.fundManager.portfolio.companyTransaction.lastValuationDate',
                { date: FormatAsLocaleDate(lastValuationDate, 'PPP') },
              )}
            </Typography>
          )}
          <Divider />
          <FormToggleButton
            name="type"
            label={t(
              'pages.fundManager.portfolio.companyTransaction.form.transactionType.title',
            )}
            control={control}
            options={Object.values(WebCompanyTransactionTypeEnum).map(
              (value) => ({
                label: t(
                  `pages.fundManager.portfolio.companyTransaction.form.transactionType.${value}`,
                ),
                value,
              }),
            )}
            fullWidth
          />
          <Stack direction="row" spacing={2}>
            <FormInputText
              type="number"
              name="quantity"
              label={t(
                'pages.fundManager.portfolio.companyTransaction.form.quantity',
              )}
              control={control}
              required
              fullWidth
            />
            <FormInputText
              type="number"
              name="totalQuantity"
              label={t(
                'pages.fundManager.portfolio.companyTransaction.form.totalQuantity',
              )}
              control={control}
              required
              fullWidth
            />
            <FormInputText
              type="number"
              name="nominalValue"
              label={t(
                'pages.fundManager.portfolio.companyTransaction.form.nominalValue',
              )}
              control={control}
              required
              fullWidth
            />
          </Stack>
          <Divider />
          <Typography variant="body2" color="textSecondary">
            {t(
              `pages.fundManager.portfolio.inventory.table.${
                type === WebCompanyTransactionTypeEnum.Transfer
                  ? 'exitValue'
                  : 'acquisitionCost'
              }`,
            ) +
              ' : ' +
              formatToMonetaryAmount(nominalValue * quantity)}
          </Typography>
          {error ? <ErrorLabel label={error.message} /> : null}
          {errorDelete ? <ErrorLabel label={errorDelete.message} /> : null}
          <ActionButton
            size="small"
            variant="submit"
            type="submit"
            loading={loading}
          >
            {t('common.actions.edit')}
          </ActionButton>
          <ActionButton
            size="small"
            variant="danger"
            onClick={handleClickDelete}
            loading={loadingDelete}
          >
            {t('common.actions.delete')}
          </ActionButton>
        </Stack>
      </FormProvider>
    </form>
  );
};
