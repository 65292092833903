import { Stack } from '@mui/material';
import { useParams } from 'react-router';

import { ShareTransferTableToValidate } from 'business/fund-manager/fund/components/share-transfer-table-to-validate';
import { SubscriptionsTable } from 'business/fund-manager/fund/components/subscription-table';
import { SubscriptionsTableToValidate } from 'business/fund-manager/fund/components/subscription-table-to-validate';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { AddShareTransferButton } from 'business/fund-manager/subscription/components/add-share-transfer-button';
import { AddSubscriptionButton } from 'business/shared/lp-creation-module/components/add-subscription-button';
import {
  Web_Portal_ManagementCompanyType_Enum,
  Web_Portal_PublicationStatus_Enum,
  useFundPendingValidationShareTransfersQuery,
  useFundPublicationStatusAndValidatedSharesQuery,
  useGetCommitmentAndOperationForFundQuery,
  useGetPendingValidationSubscriptionsQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundSubscriptionList = () => {
  const { fundId = '' } = useParams();

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const {
    data: fundData,
    loading,
    error,
  } = useFundPublicationStatusAndValidatedSharesQuery({
    variables: {
      id: fundId,
    },
    fetchPolicy: 'network-only',
  });

  // share transfers pending validation
  const {
    data: pendingValidationShareTransferData,
    loading: pendingValidationShareTransferLoading,
    refetch: pendingValidationShareTransferRefetch,
  } = useFundPendingValidationShareTransfersQuery({
    variables: { fundId },
  });

  // subscriptions pending validation
  // NOTE: we can not validate subscriptions with pending validation shareTransfer (they have been filtered out)
  const {
    data: pendingValidationSubscriptionsData,
    loading: pendingValidationSubscriptionsLoading,
    refetch: pendingValidationSubscriptionsRefetch,
  } = useGetPendingValidationSubscriptionsQuery({
    variables: { input: { fundId } },
  });

  // subscriptions validated
  const {
    data: validatedSubscriptions,
    loading: validatedSubscriptionsLoading,
    refetch: validatedSubscriptionsRefetch,
  } = useGetCommitmentAndOperationForFundQuery({
    variables: { fundId },
    fetchPolicy: 'network-only',
  });

  const onAddSubscriptionCompleted = () => {
    if (isAdvisor) {
      pendingValidationSubscriptionsRefetch();
    } else {
      validatedSubscriptionsRefetch();
    }
  };

  const onAddShareTransferCompleted = () => {
    if (isAdvisor) {
      pendingValidationShareTransferRefetch();
    } else {
      validatedSubscriptionsRefetch();
    }
  };

  const onSubscriptionValidationCompleted = () => {
    pendingValidationSubscriptionsRefetch();
    validatedSubscriptionsRefetch();
  };

  const onShareTransferValidationCompleted = () => {
    pendingValidationShareTransferRefetch();
    pendingValidationSubscriptionsRefetch();
    validatedSubscriptionsRefetch();
  };

  if (error || loading || !fundData?.fund || !fundData?.shares) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const fundHaveValidatedShares = fundData?.shares.length ? true : false;

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent={'flex-end'}>
        {fundData.fund.publicationStatus ===
          Web_Portal_PublicationStatus_Enum.Validated &&
        fundHaveValidatedShares ? (
          <Stack direction="row" spacing={2}>
            <AddSubscriptionButton
              fundId={fundId}
              onCompleted={onAddSubscriptionCompleted}
            />
            <AddShareTransferButton
              fundId={fundId}
              onCompleted={onAddShareTransferCompleted}
            />
          </Stack>
        ) : null}
      </Stack>
      {/* SHARE TRANSFERS TO VALIDATE */}
      <Stack spacing={4}>
        <ShareTransferTableToValidate
          fundId={fundId}
          data={pendingValidationShareTransferData}
          onCompleted={onShareTransferValidationCompleted}
          loading={pendingValidationShareTransferLoading}
        />

        {/* SUBSCRIPTIONS TO VALIDATE */}
        <SubscriptionsTableToValidate
          fundId={fundId}
          data={pendingValidationSubscriptionsData}
          loading={pendingValidationSubscriptionsLoading}
          onCompleted={onSubscriptionValidationCompleted}
        />

        {/* SUBSCRIPTIONS VALIDATED */}
        <SubscriptionsTable
          data={validatedSubscriptions}
          loading={validatedSubscriptionsLoading}
        />
      </Stack>
    </Stack>
  );
};
