import { formatDate } from 'date-fns';

import { useLanguageContext } from 'business/providers/language-provider';
import { formatAsDate, isISODateString } from 'technical/date';
import { getDateFnsLocaleFromLanguage } from 'technical/localization';

/**
 * Util using the date-fns fomatDate function to format a date at a locale format using our language context, in contexts outside MUI provider (ex: ag-grid)
 *
 * https://date-fns.org/v3.6.0/docs/format
 */
export const FormatAsLocaleDate = (date: string, formatStr = 'P') => {
  const { language } = useLanguageContext();
  const locale = getDateFnsLocaleFromLanguage(language);
  const formatedDate = formatAsDate(date);
  if (!isISODateString(formatedDate)) {
    return '';
  }
  return formatDate(formatedDate, formatStr, {
    locale,
  });
};
