import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { LegalEntityKycRole } from 'generated/graphql';
import { FormInputText } from 'ui/form';

export const UltimateEffectiveControlInput = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const roles = watch('roles');

  if (!roles.includes(LegalEntityKycRole.UltimateBeneficialOwner)) {
    return null;
  }

  return (
    <FormInputText
      name="ultimateEffectiveControlPercentage"
      label={t('components.lpContactUltimateEffectiveControl.label')}
      type="number"
    />
  );
};
